<template>
  <div class="my-download">
    <!-- <el-form class="download-form" label-width="70px" label-position="right">
      <el-form-item label="内容类型">
        <el-radio-group v-model="radio1">
          <el-radio-button label="全部" />
          <el-radio-button label="运动" />
          <el-radio-button label="舞蹈" />
          <el-radio-button label="互动" />
        </el-radio-group>
      </el-form-item>
      <el-form-item label="年份">
        <el-radio-group v-model="radio2" size="medium">
          <el-radio-button label="全部" />
          <el-radio-button label="2021" />
          <el-radio-button label="2020" />
        </el-radio-group>
      </el-form-item>
    </el-form>-->
    <el-row v-loading="loading">
      <el-col v-for="o in currentList" :key="o.id" :span="8">
        <div @mouseenter="o.show = true" @mouseleave="o.show = false">
          <img :src="o.coverImage" class="image" />
          <div v-show="o.show" class="mask-layer">
            <el-button class="mask-layer-btn" @click="download(o)">{{
              $t("key.download")
            }}</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <div v-show="downloadList.length <= 0" class="title">
      <img src="@/assets/no.png" />
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      class="pagination"
      background
      :current-page="currentPage"
      layout="prev, pager, next,jumper"
      :total="total"
      :page-size="pageSize"
      v-show="!downloadList.length <= 0"
    />
  </div>
</template>
<script>
import { getDownloadList, downloadLink } from "@/api/material";
import { clickA, toggle } from "@/utils/common";
export default {
  // 我的下载
  name: "MyDownload",
  components: {},
  data() {
    return {
      downloadList: [],
      loading: false,
      currentPage: 1,
      pageSize: 9,
      total: 0,
      currentList: [],
    };
  },
  created() {},
  mounted() {
    this.getCollectList();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
    },
    //分页
    handleCurrentChange(val) {
      if (val == 1) {
        this.currentList = this.downloadList.slice(0, this.pageSize);
      } else {
        this.currentList = this.downloadList.slice(
          val * this.pageSize - this.pageSize,
          val * this.pageSize
        );
      }
      // 设置页码
      this.currentPage = val;
    },
    //下载
    async download(item) {
      const { data } = await downloadLink(item.id);
      clickA(data.url, item.id);
      toggle("正在下载请稍后", "Downloading, please wait");
    },
    //获取收藏列表
    async getCollectList() {
      this.loading = true;
      try {
        const {
          data: { data },
        } = await getDownloadList();
        data.forEach((item) => {
          item.snapshot.show = false;
          this.downloadList.push(item.snapshot);
        });
        this.total = this.downloadList.length;
        this.currentList = this.downloadList.slice(0, this.pageSize);
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.my-download {
  position: relative;
  height: 100%;
  .download-form {
    margin-bottom: 28px;
    border-bottom: 1px solid #7100d1;
  }
  .el-col {
    position: relative;
    overflow: hidden;
    .image {
      cursor: pointer;
      width: 330px;
      height: 199px;
      padding-left: 7px;
      padding-bottom: 7px;
      border-radius: 15px;
    }
    .mask-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .mask-layer-btn {
        position: absolute;
        left: 50%;
        bottom: 20px;
        color: #fff;
        transform: translateX(-50%);
        width: 135px;
        height: 36px;
        background-color: #ffc322;
        border: none;
        border-radius: 18px;
      }
      .collect-btn {
        float: right;
        width: 37px;
        height: 37px;
        background: #b1b1b1;
        border-radius: 10px;
        border: none;
        margin-top: 15px;
        margin-right: 18px;
        font-size: 25px;
        padding: 0;
        color: #ffc322;
      }
    }
  }
  //分页区域
  .pagination {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 30px;
    text-align: center;
    font-size: 14px;
  }
  .title {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<style lang="scss">
.my-download,
.my-collection {
  .download-form {
    .el-radio-button {
      .el-radio-button__inner {
        border: none !important;
        border-radius: 0;
      }
      .el-radio-button__inner:hover {
        color: #7100d1;
      }
    }
    .is-active {
      .el-radio-button__inner {
        padding: 9px 13px !important;
        box-shadow: none !important;
        background-color: #7100d1 !important;
        border-radius: 20px !important;
      }
      .el-radio-button__inner:hover {
        color: #fff;
      }
    }
  }
  .pagination {
    .el-pagination__jump {
      color: #000;
      font-size: 20px !important;
      vertical-align: middle;
      .is-in-pagination {
        font-size: 20px;
        width: 68px;
        height: 40px;
        .el-input__inner {
          height: 40px;
        }
      }
    }
    .el-pager {
      .number,
      .more {
        color: #000 !important;
        background-color: rgba(0, 0, 0, 0) !important;
        line-height: 40px;
        font-size: 20px;
        font-weight: 400;
      }
      .active {
        background-color: #7f7f7f !important;
        color: #fff !important;
        width: 40px;
        height: 40px;
        border-radius: 50% !important;
      }
    }
    .btn-prev,
    .btn-next {
      color: #000 !important;
      background-color: #7f7f7f !important;
      width: 40px;
      height: 40px;
      margin: 0 40px !important;
      .el-icon {
        font-size: 20px !important;
        color: #fff;
      }
    }
  }
}
</style>
